/** DEMO */
// export const BASE_API_URL = 'https://explorer.api-sands.com/L2/';
/** DEV */
export const BASE_API_URL = 'https://test.swap.api-sands.com/L2/';
/** DEV */
// export const BASE_API_URL = 'https://test.jayx.yesbit.io/L2'
export const SUMMARY = BASE_API_URL + 'summary';
export const DAYILY_INFO = BASE_API_URL + '24hours';


export const POOL_INFO = BASE_API_URL + 'poolinfo';
export const TOKEN_INFO = BASE_API_URL + 'tokeninfo';
export const HOURLY_INFO = BASE_API_URL + 'hourinfo';
export const GET_ALL_TOKENS_STATUS = 'https://swapallapi.com:20000/api/defi/yield-farming/financial/tokens';
export const GET_TOKEN_PRICE_HISTORY = BASE_API_URL + 'token/history/price?symbol=';
export const GET_SWAP_TRANSACTIONS = BASE_API_URL + 'info/swap-transaction?';
export const GET_LIQUIDITY_TRANSACTIONS = BASE_API_URL + 'info/liquidity-transaction?';