import { tradeServices } from '../services/tradeServices';
import {
    GET_SWAP_TRANSACTIONS,
    GET_SWAP_TRANSACTIONS_FAILED,
    GET_SWAP_TRANSACTIONS_SUCCEED,
    GET_LIQUIDITY_TRANSACTIONS,
    GET_LIQUIDITY_TRANSACTIONS_SUCCEED,
    GET_LIQUIDITY_TRANSACTIONS_FAILED,
    GET_TRADE_TRANSACTIONS,
    GET_TRADE_TRANSACTIONS_FAILED,
    GET_TRADE_TRANSACTIONS_SUCCEED
} from '../constants';
import { alertActions } from './alertActions';

export const tradeActions = {
    getSwapTransactions,
    getLiquidityTransactions,
    getTradeTransactions
}
function getSwapTransactions(payload) {
    return dispatch => {
        dispatch(request());
        tradeServices.getSwapTransactions(payload)
            .then(
                res => {
                    console.log('swap record: ', res)
                    dispatch(success(res.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request() { return { type: GET_SWAP_TRANSACTIONS } }
    function success(data) { return { type: GET_SWAP_TRANSACTIONS_SUCCEED, data } }
    function failure(error) { return { type: GET_SWAP_TRANSACTIONS_FAILED, error } }
}

function getLiquidityTransactions(payload) {
    return dispatch => {
        dispatch(request());
        tradeServices.getLiquidityTransactions(payload)
            .then(
                res => {
                    dispatch(success(res.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request() { return { type: GET_LIQUIDITY_TRANSACTIONS } }
    function success(data) { return { type: GET_LIQUIDITY_TRANSACTIONS_SUCCEED, data } }
    function failure(error) { return { type: GET_LIQUIDITY_TRANSACTIONS_FAILED, error } }
}

function getTradeTransactions() {
    return dispatch => {
        dispatch(request());
        tradeServices.getLiquidityTransactions({
            current: 0,
            pageSize: 5
        })
            .then(
                res1 => {
                    tradeServices.getSwapTransactions({
                        current: 0,
                        pageSize: 5
                    }).then(
                        res2=>{
                            let res = res1.data.list;
                            res = res.concat(res2.data.list);
                            res.sort(function(a,b){
                                return new Date(b.timestamp) - new Date(a.timestamp)
                            });
                            res = res.slice(0,5);

                            console.log('trade records here: ', res)
                            dispatch(success(res));
                        },
                        error =>{
                            dispatch(failure(error.toString()));
                            dispatch(alertActions.error(error.toString()));
                        }
                        )
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request() { return { type: GET_TRADE_TRANSACTIONS } }
    function success(data) { return { type: GET_TRADE_TRANSACTIONS_SUCCEED, data } }
    function failure(error) { return { type: GET_TRADE_TRANSACTIONS_FAILED, error } }
}