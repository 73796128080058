import {
    GET_POOL_INFO, GET_POOL_INFO_SUCCEED,
    GET_POOL_INFO_FAILED, GET_TOKEN_INFO, GET_TOKEN_INFO_SUCCEED, GET_TOKEN_INFO_FAILED,
    GET_HOURLY_INFO, GET_HOURLY_INFO_SUCCEED, GET_HOURLY_INFO_FAILED, GET_ALL_TOKEN_STATUS,
    GET_ALL_TOKEN_ICONS_SUCCEED, GET_ALL_TOKEN_STATUS_SUCCEED, GET_ALL_TOKEN_STATUS_FAILED,
    GET_TOKEN_PRICE_HISTORY,GET_TOKEN_PRICE_HISTORY_SUCCEED,GET_TOKEN_PRICE_HISTORY_FAILED } from '../constants';

export function details (state = {
    poolsInfo: [],
    tokensInfo: [],
    hourlyInfo: [],
    poolsMsg: '',
    tokensMsg: '',
    hourlyMsg: '',
    tokenList: [],
    tokenIcons: {},
    tokenPriceHistory:[]
}, action) {
    switch (action.type) {
        case GET_POOL_INFO:
            return {
                ...state,
                loading: true
            }
        case GET_POOL_INFO_SUCCEED:
            return {
                ...state,
                loading: false,
                poolsInfo: action.data
                // [
                //     {
                //        "name":"BTC/ORDI",
                //        "time":"2023/01/05 14:41:00",
                //        "daily_swap_amount":12389,
                //        "daily_swap_fee":10,
                //        "daily_swaps":102,
                //        "amount":4800121,
                //        "volume":12908,
                //        "volume_increase":128,
                //        "liquidity":123072013708.0003,
                //        "trade_apy":0.1
                //     },
                //      {
                //         "name":"BTC/RSIC",
                //         "time":"2023/01/05 14:41:00",
                //         "daily_swap_amount":90237850,
                //         "daily_swap_fee":10,
                //         "daily_swaps":53,
                //         "amount":12308210,
                //         "volume":4899,
                //         "volume_increase":500,
                //         "liquidity":120937812.102832,
                //         "trade_apy":0.1
                //      },
                //      {
                //         "name":"ORDI/RSIC",
                //         "time":"2023/01/05 14:41:00",
                //         "daily_swap_amount":12039821,
                //         "daily_swap_fee":10,
                //         "daily_swaps":500,
                //         "amount":41028947,
                //         "volume":2390,
                //         "volume_increase":528,
                //         "liquidity":89612809,
                //         "trade_apy":0.1
                //      },
                //  ]
            }
        case GET_POOL_INFO_FAILED:
            return {
                ...state,
                loading: false,
                poolsMsg: action.error
            }
        case GET_TOKEN_INFO:
            return {
                ...state,
                loading: true
            }
        case GET_TOKEN_INFO_SUCCEED:
            return {
                ...state,
                loading: false,
                tokensInfo: action.data
                // [
                //     {
                //         "token":"BTC",
                //         "time":"2023/01/05 14:38:00",
                //         "price":64997.27,
                //         "increase":-10.9,
                //         "allvolume":109805.05635,
                //         "liquidity":200.0010254186152008
                //      },
                //     {
                //        "token":"ORDI",
                //        "time":"2023/01/05 14:45:00",
                //        "price":40.33,
                //        "increase":-15.03,
                //        "allvolume":2855.134452780844,
                //        "liquidity":4501.3731542368052927
                //     },
                //     {
                //         "token":"RSIC",
                //         "time":"2023/01/05 14:38:00",
                //         "price":0.005968,
                //         "increase":-23.09,
                //         "allvolume":109805.05635,
                //         "liquidity":2387981.0010254186152008
                //      },
                    
                //  ]
            }
        case GET_TOKEN_INFO_FAILED:
            return {
                ...state,
                loading: false,
                tokensMsg: action.error
            }
        case GET_HOURLY_INFO:
            return {
                ...state,
                loading: true
            }
        case GET_HOURLY_INFO_SUCCEED:
            return {
                ...state,
                loading: false,
                hourlyInfo: action.data
            }
        case GET_HOURLY_INFO_FAILED:
            return {
                ...state,
                loading: false,
                hourlyMsg: action.error
            }
        case GET_ALL_TOKEN_STATUS:
            return {
                ...state,
                loading: true
            }
        case GET_ALL_TOKEN_STATUS_SUCCEED:
            return {
                ...state,
                tokenList: action.data,
                loading: false
            }
        case GET_ALL_TOKEN_STATUS_FAILED:
            return {
                ...state,
                message: action.message,
                loading: false
            }
        case GET_ALL_TOKEN_ICONS_SUCCEED:
            return {
                ...state,
                tokenIcons: action.iconMaps
            }
        case GET_TOKEN_PRICE_HISTORY:
            return {
                ...state,
                loading: true
            }
        case GET_TOKEN_PRICE_HISTORY_SUCCEED:
            return {
                ...state,
                loading: false,
                tokenPriceHistory: action.data
            }
        case GET_TOKEN_PRICE_HISTORY_FAILED:
            return {
                ...state,
                loading: false,
                tokenPriceHistoryMsg: action.error
            }
        default:
            return state;
    }

    return state;
};
