import React, { useEffect, useState } from 'react';
import { Avatar, Box, Button, Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { summaryActions } from "../../redux/actions/summaryActions";
import { detailActions } from "../../redux/actions/detailActions";
import card from '../../images/summary/bg1.png'
import icon from '../../images/summary/icon.png'
import './Summary.css';
import { isMobile } from "react-device-detect";
import { FormatNumber } from "../../utils/FormatNumber";
import LiquiditySummary from "../LiquiditySummary/LiquiditySummary";
import TradeSummary from "../TradeSummary/TradeSummary";
import Pools from "../Pools/Pools";
import Tokens from "../Tokens/Tokens";
import { roundingDown } from "../../utils/RoundingDown";

import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import Trade from "../Trade/Trade";
import TVLRank from "../TVLRank/TVLRank";

const largeBreakPoint = 1400
const midBreakPoint = 960
const table1 = 1665
const table2 = 840

function Summary({ width, t }) {

    const openLayer2 = () => {
        const newWindow = window.open(process.env.REACT_APP_REDIRECT_URL, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }
    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch();
    const history = useHistory();
    const { summary, dailyInfo } = useSelector(state => state.summary)

    const initApidata = () => {
        dispatch(summaryActions.getSummaryInfo())
        dispatch(summaryActions.get24HourInfo())
        dispatch(detailActions.getHourlyDetails())
        dispatch(detailActions.getPoolDetails())
        dispatch(detailActions.getTokenDetails())
        dispatch(detailActions.getAllTokenStatus())
    }

    const updateSummaryInfo = () => {
        setSummaryMap({
            totalDepositUSD: summary.depositVolume,
            totalLiquidity: summary.liquidity,
            totalAccountNumber: summary.account,
            dailyTradeAmountNumber: dailyInfo.transactions,
            dailySwapAmountNumber: dailyInfo.swaps,
            dailySwapUSD: dailyInfo.swap_amount,

            dailyTradeUSD: dailyInfo.volume,
            dailySwapFeeUSD: dailyInfo.fee,
            dailyDepositAmountNumber: dailyInfo.deposit,
            dailyDepositUSD: dailyInfo.deposit_volume,
            dailyWithdrawAmountNumber: dailyInfo.withdraw,
            dailyWithdrawUSD: dailyInfo.withdraw_volume,

            totalSwapFee: summary.swapFee,
            totalPairsNumber: summary.pairs,
            totalSwapAmountNumber: summary.swaps,
            totalTradeUSD: summary.volume,
            totalSwapUSD: summary.swapAmount,
            totalTradeAmountNumber: summary.transactions
        })
    }

    useEffect(() => {
        initApidata()
        setLoading(false)
        return () => {
        }
    }, [])

    const [summaryMap, setSummaryMap] = useState({
        totalDepositUSD: 0,
        totalLiquidity: 0,
        totalAccountNumber: 0,
        dailyTradeAmountNumber: 0,
        dailySwapAmountNumber: 0,
        dailySwapUSD: 0,

        dailyTradeUSD: 0,
        dailySwapFeeUSD: 0,
        dailyDepositAmountNumber: 0,
        dailyDepositUSD: 0,
        dailyWithdrawAmountNumber: 0,
        dailyWithdrawUSD: 0,

        totalSwapFee: 0,
        totalPairsNumber: 0,
        totalSwapAmountNumber: 0,
        totalTradeUSD: 0,
        totalSwapUSD: 0,
        totalTradeAmountNumber: 0
    })

    useEffect(() => {
        if (!loading) {
            updateSummaryInfo()
        }
        return () => {
        }
    }, [summary, dailyInfo, loading])

    useEffect(() => {
        console.log('summary info: ', summary)
    }, [summary])

    const summaryTable = (data, lineCount) => {
        return (
            <Grid container style={{ marginTop: 50, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ width: "100%", height: "100%", }}>
                    <Box
                        display="flex"
                        flexWrap="wrap"
                        p={0}
                        m={0}
                        style={{ width: '100%', alignItems: 'center', justifyContent: 'center', marginBottom: 50 }}
                    >
                        {
                            Object.entries(data).map(([k, v], index) => (
                                <Box style={{
                                    height: 130,
                                    width: width > table1 ? width * 0.847 / 6 : width <= table2 || isMobile ? width * 0.847 : width * 0.847 / 3,
                                    borderTopLeftRadius: index === 0 ? 20 : 'unset',
                                    borderTopRightRadius: (isMobile && index === 0) || (width > table1 && index === 5) || (width <= table1 && width > table2 && index === 2) || (width <= table2 && index === 0) ? 20 : 'unset',
                                    borderBottomLeftRadius: (isMobile && index === 17) || (width > table1 && index === 12) || (width <= table1 && width > table2 && index === 15) || (width <= table2 && index === 17) ? 20 : 'unset',
                                    borderBottomRightRadius: index === 17 ? 20 : 'unset',
                                    border: 'solid thin #38404D',
                                }} key={k + v}>
                                    <div style={{ padding: 50, flexDirection: 'row', alignItems: 'center' }}>
                                        <Typography style={{ textAlign: 'center', color: '#5F697B' }}>
                                            {t(`${k}`)}
                                        </Typography>
                                        <Typography style={{ textAlign: 'center', color: 'white' }}>
                                            {k.includes('Number') ? null : '$'}{roundingDown(data[k], 2)}
                                        </Typography>
                                    </div>
                                </Box>
                            ))
                        }
                    </Box>
                </div>
            </Grid>
        )
    }

    const summaryPhoneTable = (title, value) => {
        return (
            <div style={{ marginRight: 24, width: 120, height: 80, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div style={{ alignItems: 'center', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    <Typography style={{ textAlign: 'center', color: '#5F697B', fontSize: 8, fontWeight: 'bold' }}>
                        {t(`${title}`)}
                    </Typography>
                    <Typography style={{ textAlign: 'center', color: 'white', fontSize: 12, fontWeight: 'bold' }}>
                        {title.includes('Number') ? null : '$'}{roundingDown(value, 2)}
                    </Typography>
                </div>
                <div style={{ width: 1, right: 0, height: '50%', backgroundColor: '#38404D' }}></div>
            </div >
        );
    }


    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
            <Grid container direction={'row'} style={{ width: '86%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {
                    isMobile ?
                        <div style={{ width: '100%', display: 'flex', height: 150, justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ width: '50%', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                                <Typography style={{ fontSize: 16, color: 'white', fontWeight: '600', textAlign: 'left' }}>{t('summaryLargeTitle')}</Typography>
                                {
                                    isMobile ?
                                        null
                                        :
                                        <Typography style={{ fontSize: 10, color: '#8E96A3', fontWeight: '600', marginTop: 8, textAlign: 'left' }}>{t('summarySmallTitle')}</Typography>

                                }
                                <button className='button__SmallCell' style={{ marginTop: 20, height: 32, width: 72, borderRadius: 25, background: 'linear-gradient(to right, #48e2b1, #5c7ed3)' }}
                                    onClick={() => openLayer2()}
                                    variant="contained">
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: 'center' }}>
                                        <Typography style={{ fontSize: 12, color: 'white', fontWeight: 'bold', textTransform: 'none', marginLeft: 16, marginRight: 16 }}>
                                            Layer2
                                        </Typography>
                                    </div>
                                </button>
                            </div>
                            <div style={{ width: '46%', height: 120, display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                                <div style={{ backgroundImage: `url(${card})`, backgroundSize: 'cover', width: '100%', height: '100%', borderRadius: 12, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>


                                    <div style={{ marginTop: 12, display: "flex", width: '100%', justifyContent: 'space-between' }}>
                                        {/* <Avatar src={icon} style={{ height: 20, width: 20, marginLeft: 12 }} /> */}

                                        <Typography style={{ fontSize: 10, color: 'white', fontWeight: 'bold', textTransform: 'none', marginRight: 12, textAlign: 'right' }}>
                                            {t('UpdateHourly')}
                                        </Typography>
                                    </div>


                                    <div style={{ marginBottom: 12 }}>
                                        <Typography style={{ fontSize: 10, color: 'white', fontWeight: 'bold', textTransform: 'none', marginLeft: 16, marginRight: 16 }}>
                                            {t('totalCapital')}
                                        </Typography>

                                        <Typography style={{ fontSize: 14, color: 'white', fontWeight: 'bold', textTransform: 'none', marginLeft: 16, marginRight: 16 }}>
                                            {`$${FormatNumber(summary.depositVolume)}`}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <Grid item style={{ justifyContent: 'center', display: 'flex', alignItems: width < largeBreakPoint ? 'center' : 'flex-start', height: 400 }} direction='column' lg={6} md={12}>
                            <Typography style={{ fontSize: width < midBreakPoint ? 24 : 50, color: 'white', fontWeight: '600', textAlign: width < midBreakPoint ? 'center' : 'left' }}>{t('summaryLargeTitle')}</Typography>
                            <Typography style={{ fontSize: width < midBreakPoint ? 12 : 20, color: '#8E96A3', fontWeight: '600', marginTop: 8, textAlign: width < midBreakPoint ? 'center' : 'left' }}>{t('summarySmallTitle')}</Typography>
                            <button className='button__SmallCell' style={{ marginTop: 20, height: 50, width: 169, borderRadius: 25, background: 'linear-gradient(to right, #48e2b1, #5c7ed3)' }}
                                onClick={() => openLayer2()}
                                variant="contained">
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: 'center' }}>
                                    <Typography style={{ fontSize: 16, color: 'white', fontWeight: 'bold', textTransform: 'none', marginLeft: 16, marginRight: 16 }}>
                                        Layer2
                                </Typography>
                                </div>
                            </button>
                        </Grid>
                }

                {
                    isMobile ?
                        null
                        :
                        <Grid item style={{ justifyContent: 'center', display: 'flex', alignItems: width < largeBreakPoint ? 'center' : 'flex-end' }} direction='column' lg={6} md={12}>
                            <div style={isMobile ? { width: 600 * 0.5, height: 330 * 0.5, display: 'flex', justifyContent: 'center' } : { width: width < midBreakPoint ? 600 * 0.7 : 600, display: 'flex', justifyContent: 'center' }}>
                                <div style={{ width: "100%", height: "100%", display: 'flex', flexDirection: 'column' }}>
                                    <div style={{ backgroundColor: 'transparent', width: "100%", height: '70%', borderRadius: 16, overflow: 'hidden' }} >
                                        <div style={{ width: '100%', height: '100%', display: 'flex' }}>
                                            <div style={{ width: '100%', height: "100%" }}  >
                                                <div style={{ backgroundImage: `url(${card})`, backgroundSize: 'cover', display: 'flex', width: '100%', height: '100%' }}>
                                                    <div style={{ width: '100%', marginTop: isMobile ? 12 : 24, marginBottom: isMobile ? 12 : 24, marginLeft: 24, marginRight: 24, display: 'flex', flexDirection: 'column' }}>
                                                        <div style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 8, paddingBottom: isMobile ? 10 : 20, flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
                                                            {/* <Avatar src={icon} style={{ height: width < midBreakPoint ? isMobile ? 20 : 40 : 55, width: width < midBreakPoint ? isMobile ? 20 : 40 : 55 }} /> */}
                                                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 16 }}>
                                                                <Typography style={{ fontSize: 16, color: 'white', fontWeight: 'bold' }} >
                                                                    {t('UpdateHourly')}
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                        <div style={{ flexDirection: 'column', paddingLeft: 20, paddingTop: width < midBreakPoint ? 10 : 30, }}>
                                                            <Typography style={{ fontSize: isMobile ? 12 : 14, color: 'white', fontWeight: 'bold' }}>
                                                                {t('totalCapital')}
                                                            </Typography>
                                                            <Typography style={{ fontSize: width < midBreakPoint ? isMobile ? 15 : 22 : 32, color: 'white', fontWeight: 'bold' }} >
                                                                {`$${FormatNumber(summary.depositVolume)}`}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                }
                {isMobile ?
                    <div style={{ width: "100%", marginTop: 24, borderRadius: 12, overflow: 'hidden', borderWidth: 1, border: '1px solid #38404D' }}>
                        <ScrollMenu>
                            {
                                Object.entries(summaryMap).map(([key, value]) =>
                                    summaryPhoneTable(key, value)
                                )
                            }
                        </ScrollMenu>
                    </div>
                    :
                    null
                }
                {
                    isMobile ?
                        null
                        :
                        summaryTable(summaryMap, 4)
                }
                <Grid container style={{ marginTop: 50, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ width: "100%", height: "100%" }}>
                        <Typography style={{ fontSize: width < midBreakPoint ? 20 : 35, color: 'white', fontWeight: '600', textAlign: width < midBreakPoint ? 'left' : 'left' }}>{t('tvl')}</Typography>
                    </div>
                    <LiquiditySummary width={width} />
                </Grid>


                <Grid container style={{ marginTop: 50, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ width: "100%", height: "100%", }}>
                        <Typography style={{ fontSize: width < midBreakPoint ? 20 : 35, color: 'white', fontWeight: '600', textAlign: width < midBreakPoint ? 'left' : 'left' }}>{t('dailyTvl')}</Typography>
                    </div>
                    <TradeSummary width={width} />
                </Grid>
                <Grid container style={{ marginTop: 50, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ width: "100%", height: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Typography style={{ fontSize: width < midBreakPoint ? 20 : 35, color: 'white', fontWeight: '600', textAlign: width < midBreakPoint ? 'left' : 'left' }}>{t('Pools')}</Typography>
                        <div style={{ alignItems: 'flex-end', justifyContent: 'center', alignSelf: 'center' }}>
                            <Button style={{ height: isMobile ? 24 : 38, borderRadius: 22, background: 'linear-gradient(to right, #48e2b1, #5c7ed3)' }}
                                variant="contained"
                                onClick={() => {
                                    let path = `pools`;
                                    history.push(path);
                                }}
                            >
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: 'center' }}>
                                    <Typography style={{ fontSize: isMobile ? 10 : 16, color: 'white', fontWeight: 'bold', textTransform: 'none', marginLeft: isMobile ? 4 : 16, marginRight: isMobile ? 4 : 16 }}>
                                        {t('more')}
                                    </Typography>
                                </div>
                            </Button>
                        </div>
                    </div>
                    <Pools width={width} showPage={false} />
                </Grid>
                <Grid container style={{ marginTop: 50, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ width: "100%", height: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Typography style={{ fontSize: width < midBreakPoint ? 20 : 35, color: 'white', fontWeight: '600', textAlign: width < midBreakPoint ? 'left' : 'left' }}>{t('Tokens')}</Typography>
                        <div style={{ alignItems: 'flex-end', justifyContent: 'center', alignSelf: 'center' }}>
                            <Button style={{ height: isMobile ? 24 : 38, borderRadius: 22, background: 'linear-gradient(to right, #48e2b1, #5c7ed3)' }}
                                variant="contained"
                                onClick={() => {
                                    let path = `tokens`;
                                    history.push(path);
                                }}
                            >
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: 'center' }}>
                                    <Typography style={{ fontSize: isMobile ? 10 : 16, color: 'white', fontWeight: 'bold', textTransform: 'none', marginLeft: isMobile ? 4 : 16, marginRight: isMobile ? 4 : 16 }}>
                                        {t('more')}
                                    </Typography>
                                </div>
                            </Button>
                        </div>
                    </div>
                    <Tokens width={width} showPage={false} />
                    <div style={{ height: 60 }}></div>

                </Grid>
                <Grid container style={{ marginTop: 50, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ width: "100%", height: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Typography style={{ fontSize: width < midBreakPoint ? 20 : 35, color: 'white', fontWeight: '600', textAlign: width < midBreakPoint ? 'left' : 'left' }}>{t('navTrade')}</Typography>
                        <div style={{ alignItems: 'flex-end', justifyContent: 'center', alignSelf: 'center' }}>
                            <Button style={{ height: isMobile ? 24 : 38, borderRadius: 22, background: 'linear-gradient(to right, #48e2b1, #5c7ed3)' }}
                                    variant="contained"
                                    onClick={() => {
                                        let path = `trade`;
                                        history.push(path);
                                    }}
                            >
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: 'center' }}>
                                    <Typography style={{ fontSize: isMobile ? 10 : 16, color: 'white', fontWeight: 'bold', textTransform: 'none', marginLeft: isMobile ? 4 : 16, marginRight: isMobile ? 4 : 16 }}>
                                        {t('more')}
                                    </Typography>
                                </div>
                            </Button>
                        </div>
                    </div>
                    <Trade width={width} showPage={false} />
                    <div style={{ height: 60 }}></div>

                </Grid>
                <Grid container style={{ marginTop: 50, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ width: "100%", height: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Typography style={{ fontSize: width < midBreakPoint ? 20 : 35, color: 'white', fontWeight: '600', textAlign: width < midBreakPoint ? 'left' : 'left' }}>{t('tvlRank')}</Typography>
                        <div style={{ alignItems: 'flex-end', justifyContent: 'center', alignSelf: 'center' }}>
                            <Button style={{ height: isMobile ? 24 : 38, borderRadius: 22, background: 'linear-gradient(to right, #48e2b1, #5c7ed3)' }}
                                    variant="contained"
                                    onClick={() => {
                                        let path = `rank`;
                                        history.push(path);
                                    }}
                            >
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: 'center' }}>
                                    <Typography style={{ fontSize: isMobile ? 10 : 16, color: 'white', fontWeight: 'bold', textTransform: 'none', marginLeft: isMobile ? 4 : 16, marginRight: isMobile ? 4 : 16 }}>
                                        {t('more')}
                                    </Typography>
                                </div>
                            </Button>
                        </div>
                    </div>
                    <TVLRank width={width} showPage={false} />
                    <div style={{ height: 60 }}></div>

                </Grid>
            </Grid>
        </div>
    )
}

function Card({ title, value, t }) {
    const visibility = React.useContext(VisibilityContext)
    return (
        <div>
            <div style={{ padding: 50, flexDirection: 'row', alignItems: 'center' }}>
                <Typography style={{ textAlign: 'center', color: '#5F697B' }}>
                    {t(`${title}`)}
                </Typography>
                <Typography style={{ textAlign: 'center', color: 'white' }}>
                    {roundingDown(value, 2)}

                </Typography>
            </div>
        </div >
    );
}


export default withTranslation()(Summary);
