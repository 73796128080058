import React, { useEffect } from 'react'
import { makeStyles, Typography, } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { createContainer, VictoryAxis, VictoryBar, VictoryChart, VictoryLabel } from 'victory'
import { FormatNumber } from "../../utils/FormatNumber";
import { roundingDown } from "../../utils/RoundingDown";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";


function TradeSummary({ width }) {
    const useStyles = makeStyles((theme) => ({
        root: {
        },
    }));

    const classes = useStyles();

    const sharedAxisStyles = {
        tickLabels: {
            fontSize: 13
        },
        axisLabel: {
            padding: 39,
            fontSize: 13,
            fontStyle: "italic"
        }
    };

    const { hourlyInfo } = useSelector(state => state.details)

    // const data = hourlyInfo.filter(item => {
    //     if (item.time.includes('00:00:00.0')) {
    //         return { x: item.time, y: item.volume }
    //     }
    // })

    let data = hourlyInfo.filter(item => item.time.includes('00:00:00.0'))
    data = data.map(item => {
        return { x: item.time, y: item.liquidity }
    })

    const VictoryZoomVoronoiContainer = createContainer("zoom", "voronoi");

    return (
        <div style={{
            background: 'linear-gradient(#191821,#0f0e14)',
            borderRadius: 20,
            display: 'flex',
            flexDirection: 'column',
            marginTop: isMobile ? 12 : 50,
            marginBottom: 50,
            width: width
        }}
        >
            <Typography style={{
                fontSize: isMobile ? 14 : 40,
                color: 'white',
                fontWeight: 'bold',
                paddingLeft: isMobile ? 24 : 40,
                paddingTop: isMobile ? 24 : 40,
            }}>
                {
                    data.length > 0 ? `$${FormatNumber(Math.floor(data[data.length - 1].y))}` : null
                }
            </Typography>
            <Typography style={{
                fontSize: isMobile ? 10 : 25,
                color: 'white',
                fontWeight: 'bold',
                paddingLeft: isMobile ? 24 : 40,
                paddingTop: 5,
                paddingBottom: isMobile ? 0 : 20
            }}>
                {
                    data.length > 0 ? data[data.length - 1].x.slice(5, 10) : null
                }
            </Typography>
            <VictoryChart
                height={isMobile ? width * 0.6 : width * 0.2}
                width={width * 0.7}
                containerComponent={
                    <VictoryZoomVoronoiContainer
                        labels={({ datum }) => `$${FormatNumber(roundingDown(datum.y, 2))} \n ${datum.x.slice(5)}`}
                    />
                }
            >
                <VictoryBar
                    style={{ data: { fill: '#2E3FBA' } }}
                    data={data}
                />
                <VictoryAxis crossAxis
                    standalone={false}
                    tickCount={isMobile ? 4 : 8}
                    tickFormat={(t) => `${typeof t === 'string' && t.length > 11 ? isMobile ? t.slice(5, 10) : t.slice(0, 10) : t}`}
                    style={{
                        axisLabel: { fontSize: 15, padding: 20 },
                        axis: { stroke: "none" }
                    }}
                    axisLabelComponent={
                        <VictoryLabel
                            dy={1}
                            dx={10}
                            angle={0}
                            style={{
                                fill: "#a6a7aa",
                                fontSize: 10,
                                // fontFamily: "'Courier New', Courier, monospace"
                            }}
                        />
                    }
                    tickLabelComponent={
                        <VictoryLabel
                            style={{
                                fill: "#545454",
                                fontSize: 15,
                                // fontFamily: "'Courier New', Courier, monospace"
                            }}
                        />
                    }
                />
            </VictoryChart>
        </div>
    )
}

export default withTranslation()(TradeSummary);
