import {
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    withStyles
} from '@material-ui/core';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { withTranslation } from 'react-i18next';
import { renderCoinWithCircBG } from "../../utils/RenderIcon";
import { FormatNumber } from "../../utils/FormatNumber";
import { roundingDown } from "../../utils/RoundingDown";
import { isMobile } from "react-device-detect";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { move } from "../../utils/Move";
import {useHistory} from "react-router-dom";
import {detailActions} from "../../redux/actions/detailActions";
import {tradeActions} from "../../redux/actions/tradeActions";

function Swap({ t, width, showPage, rowCount }) {
    const StyledTableCell = withStyles((theme) => ({
        root: {
            borderBottom: 'none',
            // textAlign: 'center'
        },
        head: {
            backgroundColor: 'transparent',
            color: '#38404D',
            borderBottom: 'solid thin #29353f',
            borderWidth: 1.6,
            fontSize: 16,
            padding: '35px 20px',
            // paddingLeft: 80

        },
        body: {
            // background:'red',
            fontSize: 16,
            color: 'white',
            padding: '25px 20px',
            // paddingLeft: 80
        }
    }))(TableCell);

    const StyledTableRow = withStyles((theme) => ({
        root: {
            backgroundColor: 'transparent'
        },
    }))(TableRow);

    const useStyles = makeStyles({
        table: {
            // minWidth: 700,
        },
        arrowUp: {
            width: 0,
            height: 0,
            borderLeft: '8px solid transparent',
            borderRight: '8px solid transparent',
            borderBottom: '8px solid green',
            marginRight: 15
        },
        arrowDown: {
            width: 0,
            height: 0,
            borderLeft: '8px solid transparent',
            borderRight: '8px solid transparent',
            borderTop: '8px solid red',
            marginRight: 15
        },
        pagination: {
            color: 'white',
            // width: '100%',
            border: 'none',
            fontSize: isMobile && showPage ? 10 : 16,
            // alignSelf: ',
            // backgroundColor:"red",
            alignItems: 'flex-start'

        },
        caption: {
            // padding: 8,
            fontSize: isMobile && showPage ? 0 : 16,
        },
        toolbar: {
            "& > p:nth-of-type(2)": {
                fontSize: isMobile && showPage ? 10 : 16,
            }
        }
    });

    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { swapTransactions } = useSelector(state => state.trade)

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(rowCount === undefined ? 5 : rowCount);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        const payload = {
            current: newPage,
            pageSize: rowsPerPage
        }
        dispatch(tradeActions.getSwapTransactions(payload))
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        const payload = {
            current: page,
            pageSize: parseInt(event.target.value, 10)
        }
        dispatch(tradeActions.getSwapTransactions(payload))
    };

    const initApidata = () => {
        const payload = {
            current: page,
            pageSize: rowsPerPage
        }
        dispatch(tradeActions.getSwapTransactions(payload))
    }

    useEffect(() => {
        initApidata()
        return () => {
        }
    }, [])

    const cellContainer = (item) => {
        return (
            <div style={{ margin: 'auto', borderRadius: 16, width: 260, marginBottom: isMobile && showPage ? 0 : 24, marginTop: isMobile && showPage ? 24 : 0, height: 110, display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#161923', justifyContent: 'space-between' }}>
                <div style={{ width: '91%', display: 'flex', justifyContent: 'space-between', marginTop: 16,fontSize: 8, color:'white' }}>
                    <div style={{width:'55%'}}>
                        {item.typeDetail.replace('Swap',t('swap')).replace('for',t('for'))}
                    </div>
                    <div style={{width:'40%', textAlign: 'right'}}>
                        {item.timestamp.slice(0, 19).replace('T',' ')}
                    </div>
                </div>

                <div style={{ width: '91%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 16 }}>
                    {/*<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>*/}
                    {/*    <Typography style={{ fontSize: 8, fontWeight: 'bold', color: '#5B5D64' }}>*/}
                    {/*        {t('swapAmount')}*/}
                    {/*    </Typography>*/}
                    {/*    <Typography style={{ fontSize: 8, fontWeight: 'bold', color: 'white' }}>*/}
                    {/*        {`$${roundingDown(item.usdValue, 2)}`}*/}
                    {/*    </Typography>*/}
                    {/*</div>*/}

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', width:"75%"}}>
                        <Typography style={{ fontSize: 8, fontWeight: 'bold', color: '#5B5D64' }}>
                            {t('swapDetails')}
                        </Typography>
                        <Typography style={{ fontSize: 8, fontWeight: 'bold', color: 'white' }}>
                            {item.detail.replace('Swap',t('swap')).replace('for',t('for'))}
                        </Typography>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', width:"20%" }}>
                        <Typography style={{ fontSize: 8, fontWeight: 'bold', color: '#5B5D64' }}>
                            {t('swapAccount')}
                        </Typography>
                        <Typography style={{ fontSize: 8, fontWeight: 'bold', color: 'white', wordBreak: 'break-word' }}>
                            {item.account}
                        </Typography>
                    </div>


                </div>
            </div >
        );
    }
    return (
        <div style={{
            width: width,
            height: isMobile && showPage ? "95%" : '80%',
            alignItems: isMobile && showPage ? "flex-start" : 'center',
            justifyContent: 'center',
            display: 'flex',
            marginTop: isMobile ? 12 : 50,
            // marginBottom: 24,
            // backgroundColor: 'yellow'

        }}>

            {

                isMobile && !showPage ?
                    <div style={{ width: "100%", marginTop: 24, overflow: 'hidden' }}>
                        <ScrollMenu>
                            {
                                swapTransactions.list.map((item, index) =>
                                    cellContainer(item)
                                )
                            }
                        </ScrollMenu>
                    </div>
                    :


                    <TableContainer component={Paper}


                                    style={{
                                        background: 'linear-gradient(180deg, #140e11,#0f0e14)',
                                        width: '100%',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: 20,
                                        border: 'solid thin #1E1D22',
                                        height: '80%'

                                    }}>
                        <Table className={classes.table} aria-label="customized table">
                            {isMobile && showPage ?
                                null
                                :
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="left" style={{paddingLeft:60}}>{t('swapType')}</StyledTableCell>
                                        <StyledTableCell align="left">{t('swapAmount')}</StyledTableCell>
                                        <StyledTableCell align="left">{t('swapDetails')}</StyledTableCell>
                                        <StyledTableCell align="left">{t('swapAccount')}</StyledTableCell>
                                        <StyledTableCell align="left" style={{paddingRight:60}}>{t('swapTime')}</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                            }

                            <TableBody>
                                {swapTransactions.list.map((row, index) => (

                                    isMobile && showPage ?
                                        cellContainer(row)

                                        :

                                        <StyledTableRow key={index}>
                                            <StyledTableCell align="left" style={{paddingLeft:60}}>{row.typeDetail.replace('Swap',t('swap')).replace('for',t('for'))}</StyledTableCell>
                                            <StyledTableCell align="left">{row.usdValue === null || row.usdValue === 0 ? '-':`$${roundingDown(row.usdValue, 2)}`}</StyledTableCell>
                                            <StyledTableCell align="left">{row.detail.replace('Swap',t('swap')).replace('for',t('for'))}</StyledTableCell>
                                            <StyledTableCell align="left">{row.account === null ? '-' : row.account}</StyledTableCell>
                                            <StyledTableCell align="left" style={{paddingRight:60, wordBreak:'break-all'}}>{row.timestamp.slice(0, 19).replace('T',' ')}</StyledTableCell>
                                        </StyledTableRow>

                                ))}
                            </TableBody>
                            {
                                showPage ?
                                    <TableFooter style={{}}>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[5, 10, 25]}
                                                count={swapTransactions.total/2}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                SelectProps={{
                                                    inputProps: { 'aria-label': 'rows per page' },
                                                    native: true,
                                                }}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                classes={{ root: classes.pagination, caption: classes.caption, toolbar: classes.toolbar }}
                                            />
                                        </TableRow>
                                    </TableFooter> : null
                            }
                        </Table>
                    </TableContainer>
            }

        </div>
    )
}

export default withTranslation()(Swap);
