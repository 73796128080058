import {
    GET_SWAP_TRANSACTIONS,
    GET_SWAP_TRANSACTIONS_SUCCEED,
    GET_SWAP_TRANSACTIONS_FAILED,
    GET_LIQUIDITY_TRANSACTIONS,
    GET_LIQUIDITY_TRANSACTIONS_FAILED,
    GET_LIQUIDITY_TRANSACTIONS_SUCCEED,
    GET_TRADE_TRANSACTIONS,
    GET_TRADE_TRANSACTIONS_SUCCEED,
    GET_TRADE_TRANSACTIONS_FAILED
} from '../constants';

export function trade (state = {
    swapTransactions:{
        list:[],
        page:0,
        size:10,
        isEnd:false,
        total:0
    },
    liquidityTransactions:{
        list:[],
        page:0,
        size:10,
        isEnd:false,
        total:0
    },
    tradeTransactions:[]
}, action) {
    switch (action.type) {
        case GET_SWAP_TRANSACTIONS:
            return {
                ...state,
                loading: true,
                swapTransactions:{
                    list:[],
                    page:0,
                    size:10,
                    isEnd:false,
                    total:0
                }
            }
        case GET_SWAP_TRANSACTIONS_SUCCEED:
            return {
                ...state,
                loading: false,
                swapTransactions: action.data
                // {
                //     "list":[
                //         {
                //            "typeDetail":"Swap BTC for ORDI",
                //            "usdValue":64890,
                //            "detail":"Swap 0.1 BTC for 148 ORDI",
                //            "account":"bc1pusyyslevc5hwrafe20kny3gnqhgcp6lde9l4r0n8slqpw79389gqw7w0pt",
                //            "timestamp":"2024-06-02T19:27:12.000+00:00"
                //         },
                //         {
                //            "typeDetail":"Swap BTC for RSIC",
                //            "usdValue":1950.03,
                //            "detail":"Swap 0.03 BTC for 325000 RSIC",
                //            "account":"bc1pjdxfynlellqp0kk3q6xc8kzcypyd9ssr6zfadve8td6w52etz6eq4la0ap",
                //            "timestamp":"2024-05-03T19:27:11.000+00:00"
                //         },
                //         {
                //            "typeDetail":"Swap ORDI for RSIC",
                //            "usdValue":890,
                //            "detail":"Swap 20 ORDI for 146667 RSIC",
                //            "account":"bc1pacj06mtux83w564t89hdwp47w9jlle0dvgpacpce8ah9w2th0dqs72fgc2",
                //            "timestamp":"2024-02-13T19:27:11.000+00:00"
                //         }
                //      ],
                //     "page":1,
                //     "size":3,
                //     "isEnd":false,
                //     "total":192554
                //  }
            }
        case GET_SWAP_TRANSACTIONS_FAILED:
            return {
                ...state,
                loading: false,
                swapTransactionsMsg: action.error
            }
        case GET_LIQUIDITY_TRANSACTIONS:
            return {
                ...state,
                loading: true,
                liquidityTransactions:{
                    list:[],
                    page:0,
                    size:10,
                    isEnd:false,
                    total:0
                }
            }
        case GET_LIQUIDITY_TRANSACTIONS_SUCCEED:
            return {
                ...state,
                loading: false,
                liquidityTransactions: action.data
                // {
                //     "list":[
                //         {
                //            "typeDetail":"Remove BTC & ORDI",
                //            "usdValue":42867.23,
                //            "detail":"Remove 487.5 ORDI & 0.33 BTC",
                //            "account":"bc1pjdxfynlellqp0kk3q6xc8kzcypyd9ssr6zfadve8td6w52etz6eq4la0ap",
                //            "timestamp":"2024-06-18T19:27:12.000+00:00"
                //         },
                //         {
                //            "typeDetail":"Remove ORDI & RSIC",
                //            "usdValue":890.01,
                //            "detail":"Remove 10 ORDI & 73333.33 RSIC",
                //            "account":"bc1pusyyslevc5hwrafe20kny3gnqhgcp6lde9l4r0n8slqpw79389gqw7w0pt",
                //            "timestamp":"2024-04-23T19:27:11.000+00:00"
                //         },
                //         {
                //            "typeDetail":"Add RSIC & BTC",
                //            "usdValue": 13000,
                //            "detail":"Add 110000 RSIC & 0.1 BTC",
                //            "account":"bc1pacj06mtux83w564t89hdwp47w9jlle0dvgpacpce8ah9w2th0dqs72fgc2",
                //            "timestamp":"2024-03-23T19:27:11.000+00:00"
                //         }
                //      ],
                //     "page":1,
                //     "size":3,
                //     "isEnd":false,
                //     "total":192554
                //  }
            }
        case GET_LIQUIDITY_TRANSACTIONS_FAILED:
            return {
                ...state,
                loading: false,
                liquidityTransactionsMsg: action.error
            }

        case GET_TRADE_TRANSACTIONS:
            return {
                ...state,
                loading: true,
                tradeTransactions:[]
            }
        case GET_TRADE_TRANSACTIONS_SUCCEED:
            return {
                ...state,
                loading: false,
                tradeTransactions: action.data
                // [
                //     {
                //         "typeDetail":"Remove BTC & ORDI",
                //         "usdValue":42867.23,
                //         "detail":"Remove 487.5 ORDI & 0.33 BTC",
                //         "account":"bc1pjdxfynlellqp0kk3q6xc8kzcypyd9ssr6zfadve8td6w52etz6eq4la0ap",
                //         "timestamp":"2024-06-18T19:27:12.000+00:00"
                //      },
                //     {
                //         "typeDetail":"Swap BTC for ORDI",
                //         "usdValue":64890,
                //         "detail":"Swap 0.1 BTC for 148 ORDI",
                //         "account":"bc1pusyyslevc5hwrafe20kny3gnqhgcp6lde9l4r0n8slqpw79389gqw7w0pt",
                //         "timestamp":"2024-06-02T19:27:12.000+00:00"
                //      },
                //      {
                //         "typeDetail":"Swap BTC for RSIC",
                //         "usdValue":1950.03,
                //         "detail":"Swap 0.03 BTC for 325000 RSIC",
                //         "account":"bc1pjdxfynlellqp0kk3q6xc8kzcypyd9ssr6zfadve8td6w52etz6eq4la0ap",
                //         "timestamp":"2024-05-03T19:27:11.000+00:00"
                //      },
                //      {
                //         "typeDetail":"Remove ORDI & RSIC",
                //         "usdValue":890.01,
                //         "detail":"Remove 10 ORDI & 73333.33 RSIC",
                //         "account":"bc1pusyyslevc5hwrafe20kny3gnqhgcp6lde9l4r0n8slqpw79389gqw7w0pt",
                //         "timestamp":"2024-04-23T19:27:11.000+00:00"
                //      },
                //      {
                //         "typeDetail":"Add RSIC & BTC",
                //         "usdValue": 13000,
                //         "detail":"Add 110000 RSIC & 0.1 BTC",
                //         "account":"bc1pacj06mtux83w564t89hdwp47w9jlle0dvgpacpce8ah9w2th0dqs72fgc2",
                //         "timestamp":"2024-03-23T19:27:11.000+00:00"
                //      },
                //     {
                //         "typeDetail":"Swap ORDI for RSIC",
                //         "usdValue":890,
                //         "detail":"Swap 20 ORDI for 146667 RSIC",
                //         "account":"bc1pacj06mtux83w564t89hdwp47w9jlle0dvgpacpce8ah9w2th0dqs72fgc2",
                //         "timestamp":"2024-02-13T19:27:11.000+00:00"
                //      }
                //  ]
            }
        case GET_TRADE_TRANSACTIONS_FAILED:
            return {
                ...state,
                loading: false,
                tradeTransactionsMsg: action.error
            }
        default:
            return state;
    }

    return state;
};
